import { ProductEntity } from "./product";
import { UserProfileEntity } from "./users";

export interface UserGiftEntity {
  id: number;
  product: ProductEntity;
  target: UserProfileEntity;
  user: UserProfileEntity;
  order: Order;
  attachments: any[];
  comments: any[];
  data: {};
  contact: UserProfileEntity;
  timestamp: string;
  edited_timestamp: string;
  unwrapped: boolean;
  unwrapped_timestamp: null;
  redeemed: boolean;
  redeemed_timestamp: null;
  archived: boolean;
  expiring_timestamp: string | null;
  expired: boolean;
  free: boolean;
  status: OrderStatus;
}

export interface Order {
  id: number;
  url: null | string;
  paid: number;
  cost: number;
  fee: number;
  status: OrderStatus;
  user: UserProfileEntity;
  target: UserProfileEntity;
  product: ProductEntity;
  price?: number;
}

export interface CohortGiftEntity {
  id: number;
  user: UserProfileEntity;
  target: UserProfileEntity;
  product: ProductEntity;
  order: Order;
  timestamp: string;
  edited_timestamp: string;
}

export enum OrderStatus {
  PENDING = "PENDING",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
  OTHER = "OTHER",
}

export interface SentGiftEntity {
  users_id?: number[];
  product_id: number;
  video_id?: number | string;
  comment_id?: number;
}

export interface GiftComments {
  id: number;
  comment: string;
  user: number;
  edited_timestamp: string;
  read: boolean;
  timestamp: string;
}

export enum GiftTypes {
  GIFT = "gift",
  GIFT_CAMPAIGN = "gift_campaign",
}
