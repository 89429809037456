import React from "react";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";

import { Container, Loader, useNotify } from "ebs-design";

import { AlertErrors, ConfirmModal } from "components";
import { users } from "api";
import { UserProfileEntity } from "types";

import {
  UserStats,
  UserFavourites,
  UserDetails,
  UserGifts,
  UserCompanyDetails,
} from "../UserProfile";

const UserProfile = () => {
  const id = Number(useParams<{ id: string }>().id);
  const history = useHistory();
  const notify = useNotify();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [confirmBlockOpen, setConfirmBlockOpen] = React.useState(false);

  if (!id) history.push("/users/");

  const { data: user, isLoading, error } = useQuery<
    UserProfileEntity,
    AxiosError
  >(["users", id], () => users.getById(id || 0), {
    enabled: !!id,
  });

  const {
    mutate: mutateDelete,
    isLoading: isDeleteLoading,
    isError: isDeleteError,
    error: deleteError,
  } = useMutation<unknown, AxiosError>(() => users.delete(id), {
    onSuccess: () => {
      history.push("/users/");
    },
  });

  const {
    mutate: mutateBlock,
    isLoading: isBlockLoading,
    isError: isBlockError,
    error: blockError,
  } = useMutation<unknown, AxiosError>(
    () => users.patch(id, { is_suspended: true }),
    {
      onSuccess: () => {
        history.push("/users/");
        notify.success({
          title: "",
          description: "User was successfully blocked.",
        });
      },
    },
  );

  return (
    <Container size="lg">
      <AlertErrors error={error} />
      <Loader loading={isLoading} />
      {user && (
        <>
          <UserStats user={user} />
          <div className="pady-4"></div>

          <UserDetails
            user={user}
            onBlockClick={() => setConfirmBlockOpen(true)}
            onDeleteClick={() => setConfirmDeleteOpen(true)}
          />
          {user.company && (
            <>
              <div className="pady-2"></div>
              <UserCompanyDetails company={user.company} />
            </>
          )}
          <div className="pady-4"></div>

          <UserFavourites userId={user.id} />

          <div className="pady-4"></div>
          <UserGifts userId={user.id} />

          <ConfirmModal
            open={confirmDeleteOpen}
            onConfirm={() => mutateDelete()}
            onClose={() => setConfirmDeleteOpen(false)}
            onCancel={() => setConfirmDeleteOpen(false)}
            loading={isDeleteLoading}
          >
            Are you sure to delete this user?
            {isDeleteError && deleteError && (
              <AlertErrors error={deleteError} />
            )}
          </ConfirmModal>

          <ConfirmModal
            open={confirmBlockOpen}
            onConfirm={() => mutateBlock()}
            onClose={() => setConfirmBlockOpen(false)}
            onCancel={() => setConfirmBlockOpen(false)}
            loading={isBlockLoading}
          >
            Are you sure to block this user?
            {isBlockError && blockError && <AlertErrors error={blockError} />}
          </ConfirmModal>
        </>
      )}
    </Container>
  );
};

export default UserProfile;
