import { Form, Input, Select, Textarea } from "ebs-design";
import { GiftTypes } from "types";

const giftOptions = {
  gift: "Gift",
  gift_campaign: "Gift Campaign",
};

export const CouponDetailsFields = () => {
  return (
    <>
      <Form.Field
        label="Name"
        name="name"
        rules={[{ required: true }]}
        initialValue=""
      >
        <Input />
      </Form.Field>
      <Form.Field
        label="Max usage"
        name="max_usage"
        rules={[{ required: true, min: 1 }]}
        initialValue=""
      >
        <Input type="number" />
      </Form.Field>
      <Form.Field
        label="Total amount available"
        name="total_amount"
        rules={[{ required: true, min: 1 }]}
        initialValue=""
      >
        <Input type="number" />
      </Form.Field>
      <Form.Field
        label="Message"
        name="message"
        rules={[{ required: true }]}
        initialValue=""
      >
        <Textarea />
      </Form.Field>

      <Form.Field
        label="Gift validity duration (days)"
        name="gift_validity_duration"
        initialValue=""
      >
        <Input type="number" min="0" />
      </Form.Field>

      <Form.Field name="coupon_type" label="Coupon type">
        <Select
          options={Object.values(GiftTypes).map((type) => ({
            text: giftOptions[type as keyof typeof giftOptions],
            value: type,
          }))}
        />
      </Form.Field>
    </>
  );
};
